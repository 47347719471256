import axios from "axios";

export default {
  namespaced: true,
  state: {
    mobile: "",
    name: "",
    country: "",
    seller_id: "",
    agent_id: "",
    channel_id: "",
    token: "",
    is_tele_caller: 0,
    remark_needed: 0,
    pusher: "",
    pusherChannel: ""
  },
  getters: {
    isAuthenticated(state) {
      if (state.token) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${state.token}`;
        return true;
      }
      else {
        return false;
      }
    },
    getToken(state) {
      return state.token;
    },
    getChannel(state) {
      return state.channel_id;
    },
    getName(state) {
      return state.name;
    },
    getCountry(state) {
      return state.country;
    },
    getTeleCallerCheck(state) {
      return state.is_tele_caller;
    },
    getAgentId(state) {
      return state.agent_id;
    },
    getRemarkCheck(state) {
      return state.remark_needed;
    },
  },
  mutations: {
    setStates: (state, model) => {
      if (model == "") {
        state.mobile = "";
        state.country = "";
        state.name = "";
        state.seller_id = "";
        state.agent_id = "";
        state.channel_id = "";
        state.token = "";
        state.is_tele_caller = 0;
        state.remark_needed = 0;
        state.chat_id = "";
      }
      else {
        state.mobile = model.mobile;
        state.name = model.name;
        state.country = model.country;
        state.seller_id = model.seller_id;
        state.agent_id = model.agent_id;
        state.channel_id = model.channel;
        state.token = model.token;
        state.is_tele_caller = model.is_tele_caller;
        state.remark_needed = model.remark_needed;
      }
    }
  },
  actions: {
    login: async ({ commit }, model) => {
      commit("setStates", model);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${model.token}`;
    },
    logout: ({ commit }) => {
      commit("setStates", "");
    }
  },
};