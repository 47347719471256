<template>
  <div class="side-nav">
    <div class="main-menu">
      <ul class="metismenu" id="menu">
        <li
          class="Ul_li--hover mb-32"
          :class="props.route == 'messages' ? 'sidebar-active' : ''"
        >
          <router-link
            class="unlink d-flex justify-content-center"
            :to="'/messages'"
            ><i
              :class="props.route == 'messages' ? 'font-white' : 'font-purple'"
              class="bi bi-chat-left-text text-30 mr-2"
            ></i
          ></router-link>
        </li>

        <li
          v-if="isTeleCaller == 0"
          class="Ul_li--hover mb-32"
          :class="props.route == 'scheduled' ? 'sidebar-active' : ''"
        >
          <router-link
            class="unlink d-flex justify-content-center"
            :to="'/scheduled'"
            ><i
              :class="props.route == 'scheduled' ? 'font-white' : 'font-purple'"
              class="bi bi-calendar-fill text-30 mr-2"
            ></i
          ></router-link>
        </li>

        <li
          v-if="isTeleCaller == 0"
          class="Ul_li--hover mb-32"
          :class="props.route == 'missed' ? 'sidebar-active' : ''"
        >
          <router-link
            class="unlink d-flex justify-content-center"
            :to="'/missed'"
            ><i
              :class="props.route == 'missed' ? 'font-white' : 'font-purple'"
              class="bi bi-telephone-x-fill text-30 mr-2"
            ></i
          ></router-link>
        </li>
        <li
          class="Ul_li--hover mb-32"
          :class="props.route == 'leads' ? 'sidebar-active' : ''"
        >
          <router-link
            class="unlink d-flex justify-content-center"
            :to="'/leads'"
            ><i
              :class="props.route == 'leads' ? 'font-white' : 'font-purple'"
              class="bi bi-people-fill text-30 mr-2"
            ></i
          ></router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useStore } from "vuex";
const store = useStore();
const isTeleCaller = store.getters["auth/getTeleCallerCheck"];
const props = defineProps({
  route: String,
});
</script>

<style scoped>
.font-purple {
  color: #710ec5;
}

.font-white {
  color: #fff;
}
</style>
