<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<style>
.gull-brand {
  height: 60px;
}

.gull-brand img {
  height: 100%;
  width: 100%;
}
</style>
