import DashboardView from "../views/DashboardView.vue";
import ChatView from "../views/ChatView.vue";
import RoomView from "../views/RoomView.vue";
import ScheduledView from "../views/ScheduledView.vue";
import MissedView from "../views/MissedView.vue";
import LeadsView from "../views/LeadsView.vue";
import ProfileView from "../views/ProfileView.vue";

const routes = (store) => [
  {
    path: "/",
    name: "",
    redirect: "messages",
    beforeEnter: (to, from, next) => {
      let authenticated = store.getters["auth/isAuthenticated"];
      if (authenticated != true) {
        next("/login");
        return;
      }
      next();
    },
    component: DashboardView,
    children: [
      {
        path: "/messages",
        name: "messages",
        component: ChatView,
      },
      {
        path: "/room",
        name: "room",
        component: RoomView,
      },

      {
        path: "/scheduled",
        name: "scheduled",
        component: ScheduledView,
      },
      {
        path: "/missed",
        name: "missed",
        component: MissedView,
      },
      {
        path: "/leads",
        name: "leads",
        component: LeadsView,
      },
      {
        path: "/profile",
        name: "profile",
        component: ProfileView,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
    props: true,
  },
];

export default (store) => routes(store);
