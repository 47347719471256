<template>
  <div class="main-content pt-4">
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-body">
            <div class="table-responsive px-28">
              <div class="text-center" v-if="loader">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <table v-else class="display table" id="merchantsTable">
                <tbody v-if="schedules.length > 0">
                  <tr
                    class="main-tr"
                    v-for="schedule in schedules"
                    :key="schedule.id"
                  >
                    <td class="align-middle">
                      <div class="d-flex align-items-center">
                        <div
                          class="avatar text-light text-12 font-inter rounded-circle d-flex justify-content-center align-items-center text-uppercase"
                        >
                          {{ schedule.name.substring(0, 2) }}
                        </div>
                        <span
                          class="ml-12 font-inter text-14 font-weight-400"
                          >{{ schedule.name }}</span
                        >
                      </div>
                    </td>
                    <td
                      class="align-middle font-inter text-14 font-weight-400 font-14"
                    >
                      {{ schedule.scheduled_by }}
                    </td>
                    <td
                      class="align-middle font-inter text-14 font-weight-400 font-14"
                    >
                      At {{ schedule.schedule_time_human }}
                    </td>

                    <!-- <td class="align-middle">
                      <span v-if="schedule.schedule_time_human && checkThevalidTime(schedule.schedule_time) <= 10"
                        class="d-flex justify-content-end" @click="joinSchedule(schedule.id)">
                        <CustomButton btnName="Join" btnColor="#710EC5" padding="12px 30px" />
                      </span>
                    </td> -->
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="3">
                      <h2 class="text-center text-muted">No Schedule Call</h2>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!---->
          </div>
        </div>
      </div>
    </div>
    <!-- end of main-content -->
  </div>
</template>
<script setup>
import axios from "axios";
import { ref, onMounted } from "vue";
// import CustomButton from "@/components/CustomButton.vue";
const schedules = ref([]);
const loader = ref(false);

onMounted(() => {
  getScheduled();
});
// function checkThevalidTime(date) {
//   const date1 = new Date(date); // Replace with your actual date
//   const date2 = new Date(); // Replace with your actual date

//   console.log(date1 + "date1" + date2 + "date2");

//   // Calculate time difference in minutes
//   const timeDifferenceInMillis = date1 - date2; // Time difference in milliseconds
//   const timeDifferenceInMinutes = Math.floor(
//     timeDifferenceInMillis / (60 * 1000)
//   ); // Time difference in minutes

//   console.log(timeDifferenceInMinutes);
//   return timeDifferenceInMinutes;
// }
function getScheduled() {
  loader.value = true;

  axios
    .get(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/connection/scheduled")
    .then((res) => {
      if (res.data.status == 1) {
        schedules.value = res.data.requests;
      }
    })
    .finally(() => {
      loader.value = false;
    });
}

// function joinSchedule(id) {
//   //v1/seller/connection/notify
//   if (confirm("Do you really want to join this schedule?")) {
//     axios
//       .post(
//         process.env.VUE_APP_POPIN_URL + "/api/v1/seller/connection/notify",
//         {
//           connect_request_id: id,
//         }
//       )
//       .then((res) => {
//         if (res.data.status == 1) {
//           getScheduled();
//         }
//       });
//   }
// }
</script>
<style scoped>
.table-responsive {
  max-height: calc(98vh - 120px) !important;
}

.table td {
  padding: 12px 0px !important;
}
</style>
