<template>
  <div class="main-content pt-5 px-16">
    <div class="row">
      <div
        class="card border-0 chat-sidebar-wrap sidebar mb-28 col-3 p-2"
        data-sidebar="chat"
      >
        <div class="row mb-2 justify-content-between">
          <h4 class="col-md-6 m-2"></h4>
          <!-- <div class="col-md-auto">
                  <button class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#createContactModal">+</button>
                </div> -->
        </div>
        <div class="contacts-scrollable contacts">
          <!-- online - green icon -->
          <div class="text-center" v-if="loader">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else>
            <div>
              <input
                placeholder="Search"
                class="popin-form-control text-15 font-weight-400 input-otp mb-16"
                type="input"
                v-model="searchChat"
              />
            </div>
            <div
              v-for="(contact, key) in filteredChat"
              :key="key"
              class="box-border"
            >
              <div
                class="p-2 contact"
                :class="getClass(contact)"
                @click.prevent="getMessages(contact.user_id, contact)"
              >
                <div class="d-flex align-items-center">
                  <div
                    class="avatar text-light text-12 font-inter rounded-circle d-flex justify-content-center align-items-center text-uppercase"
                  >
                    {{ contact.user_name.substring(0, 2) }}
                  </div>
                  <p class="mb-0 ml-16 text-14 font-weight-400 font-inter">
                    {{ contact.user_name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="selectedUser.user_id">
        <div class="col-6">
          <message-list-component
            :userName="selectedUser.user_name"
            :userId="selectedUser.user_id"
            :mobile="selectedUser.mobile"
          ></message-list-component>
          <div class="chat-input-area pt-2">
            <sent-message-component
              :userId="selectedUser.user_id"
              from="chat"
            ></sent-message-component>
          </div>
        </div>

        <div class="col-3">
          <user-profile-component
            :userId="selectedUser.user_id"
          ></user-profile-component>
        </div>
      </template>
      <div class="col-9 mb-28" v-else>
        <div
          class="h-100 card border-0 d-flex justify-content-center align-items-center img-cart"
        >
          <img src="images/chat-bg.svg" width="400" />
          <span class="text-center" style="width: 50%"
            >Use Popin Web to answer calls, send and receive messages, manage
            scheduled calls and target missed customers.</span
          >
        </div>
      </div>
    </div>
    <!-- end of main-content -->
    <LastViewedProductsModalComponent :user_id="selectedUser.user_id" />
    <CreateContactModal />
  </div>
</template>

<script>
import { ref, computed } from "@vue/reactivity";
import axios from "axios";
import { onMounted } from "@vue/runtime-core";
import SentMessageComponent from "../components/SentMessageComponent.vue";
import MessageListComponent from "../components/MessageListComponent.vue";
import UserProfileComponent from "../components/UserProfileComponent.vue";
import LastViewedProductsModalComponent from "../components/LastViewedProductsModalComponent.vue";
import CreateContactModal from "../components/CreateContactModal.vue";

export default {
  components: {
    SentMessageComponent,
    MessageListComponent,
    UserProfileComponent,
    LastViewedProductsModalComponent,
    CreateContactModal,
  },
  setup() {
    const users = ref([]);
    const searchChat = ref("");
    const selectedUser = ref({
      user_id: "",
      user_name: "",
      mobile: "",
    });
    const loader = ref(false);
    function getClass(contact) {
      var newClass = "";
      if (contact.user_id == selectedUser.value.user_id) {
        newClass += "contact-selected";
      }
      if (contact.is_online == 1) {
        newClass += " online";
      }

      return newClass;
    }

    function getContacts() {
      loader.value = true;
      axios
        .get(
          process.env.VUE_APP_POPIN_URL + "/api/v1/seller/messages/user/list"
        )
        .then((res) => {
          if (res.data.status == 1) {
            users.value = res.data.users;
          }
        })
        .finally(() => {
          loader.value = false;
        });
    }

    function getMessages(user_id, data) {
      selectedUser.value = data;
      // selectedUser.value.user_id = user_id;
      // selectedUser.value.user_name = user_name;
    }

    onMounted(() => {
      getContacts();
    });

    const filteredChat = computed(() => {
      return users.value.filter((user) => {
        // console.log(user.user_name)
        // console.log('searchChat', searchChat.value)
        return user.user_name
          .toLowerCase()
          .includes(searchChat.value.toLowerCase());
      });
    });
    return {
      users,
      selectedUser,
      getContacts,
      getMessages,
      getClass,
      loader,
      filteredChat,
      searchChat,
    };
  },
};
</script>

<style scoped>
.popin-form-control {
  background: rgba(217, 217, 217, 0.3);
  border-radius: 20px;
  color: rgba(55, 55, 55, 0.8);
  border: none;
  outline: none;
  width: 100%;
  height: 43px;
}
.popin-form-control::placeholder {
  font-size: 13px;
  color: rgba(55, 55, 55, 0.5);
}
.input-otp {
  padding: 12px 12px 12px 20px !important;
}
/* .card {
  //height: calc(98vh - 120px);
  height: 98% !important;
} */
.img-cart {
  height: calc(98vh - 120px);
}
.brs {
  margin: 0 0 0 auto;
  width: 90%;
  border-bottom: 1px solid lightgrey;
}

.-scrollable {
  overflow-y: scroll;
  scrollbar-width: thin;
}

.sidebar-content {
  width: calc(100% - 260px);
  margin-left: auto;
}

.chat-sidebar-wrap {
  min-height: calc(96.4vh - 115px);
  overflow-x: hidden;
}

.contact-selected {
  background: #d4b8ef;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: #ececec;
}

::-webkit-scrollbar-thumb {
  background: #663399;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(102, 51, 150, 0.75);
}

::-webkit-scrollbar-corner {
  background: #663399;
}
</style>
