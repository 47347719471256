<template>
  <div class="main-content pt-4">
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-body">
            <div class="table-responsive px-28">
              <div class="text-center" v-if="loader">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <table v-else class="display table" id="merchantsTable">
                <tbody>
                  <tr v-for="miss in missed" :key="miss.id">
                    <td class="align-middle">
                      <div class="d-flex align-items-center">
                        <span
                          class="avatar text-light text-12 font-inter rounded-circle d-flex justify-content-center align-items-center text-uppercase"
                          >{{ miss.name.substring(0, 2) }}</span
                        >
                        <span
                          class="ml-12 font-inter text-14 font-weight-400"
                          >{{ miss.name }}</span
                        >
                      </div>
                    </td>
                    <td class="align-middle text-14">
                      At {{ miss.missed_time_human }}
                    </td>
                    <td class="align-middle">
                      <span
                        class="d-flex justify-content-end"
                        @click="inviteMissed(miss.id)"
                      >
                        <CustomButton
                          btnName="Invite"
                          btnColor="#710EC5"
                          padding="12px 30px"
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="align-middle">
                      <ul class="pagination">
                        <li
                          v-for="(page, index) in pages"
                          :key="index"
                          :class="['page-item', page.active ? 'active' : '']"
                        >
                          <button
                            class="page-link"
                            @click="
                              () => {
                                paginate(page.page);
                              }
                            "
                          >
                            {{ page.label }}
                          </button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!---->
          </div>
        </div>
      </div>
    </div>
    <!-- end of main-content -->
  </div>
</template>
<script setup>
import axios from "axios";
import { ref, onMounted, nextTick } from "vue";
import CustomButton from "@/components/CustomButton.vue";

const missed = ref([]);
const pages = ref([]);
const currentPage = ref(1);
const loader = ref(false);

onMounted(async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get("page");
  currentPage.value = page ?? 1;
  await nextTick();
  getMissed();
});

function getMissed() {
  loader.value = true;
  let getURL =
    process.env.VUE_APP_POPIN_URL +
    "/api/v1/seller/connection/missed?page=" +
    currentPage.value;

  axios
    .get(getURL)
    .then((res) => {
      +console.log(res.data);
      if (res.data.status == 1) {
        missed.value = res.data.requests;
        pages.value = res.data.pages;
      }
    })
    .finally(() => {
      loader.value = false;
    });
}

function inviteMissed(id) {
  if (confirm("Do you really want to notify this user?")) {
    axios
      .post(
        process.env.VUE_APP_POPIN_URL +
          "/api/v1/seller/connection/missed/invite",
        {
          id: id,
        }
      )
      .then((res) => {
        if (res.data.status == 1) {
          getMissed();
        }
      });
  }
}

const paginate = (page = 1) => {
  if (page == currentPage.value) {
    return;
  }
  window.history.pushState({}, "missed", "/missed?page=" + page ?? 1);
  currentPage.value = page;
  nextTick();
  getMissed();
};
</script>
<style scoped>
.table-responsive {
  max-height: calc(98vh - 120px) !important;
}
.table td {
  padding: 12px 0px !important;
}

.pagination li button {
  color: #710ec5 !important;
}

.pagination li.active button {
  color: #ffffff !important;
  background-color: #710ec5 !important;
  border: 1px solid #710ec5;
}
</style>
