<template>
  <div class="app-admin-wrap layout-sidebar-vertical sidebar-full">
    <div v-if="currentRoute !== 'room'" class="sidebar-panel">
      <div
        class="gull-brand pr-3 text-center mt-4 mb-2 d-flex justify-content-center align-items-center"
      >
        <img class="pl-3 mt-32" src="images/Popin_logo.svg" alt="Popin" />
      </div>
      <div
        class="scroll-nav ps ps--active-y"
        data-perfect-scrollbar="data-perfect-scrollbar"
        data-suppress-scroll-x="true"
      >
        <Sidebar :route="currentRoute" />
        <div class="ps__rail-x" style="left: 0px; bottom: 0px">
          <div
            class="ps__thumb-x"
            tabindex="0"
            style="left: 0px; width: 0px"
          ></div>
        </div>
        <!-- <div class="ps__rail-y" style="top: 0px; height: 404px; right: 0px">
          <div
            class="ps__thumb-y"
            tabindex="0"
            style="top: 0px; height: 10px"
          ></div>
        </div>
        <div class="ps__rail-x" style="left: 0px; bottom: 0px">
          <div
            class="ps__thumb-x"
            tabindex="0"
            style="left: 0px; width: 0px"
          ></div>
        </div>
        <div class="ps__rail-y" style="top: 0px; height: 404px; right: 0px">
          <div
            class="ps__thumb-y"
            tabindex="0"
            style="top: 0px; height: 10px"
          ></div>
        </div> -->
      </div>
    </div>
    <div
      class="main-content-wrap mobile-menu-content bg-off-white m-0"
      :class="
        currentRoute !== 'room'
          ? 'main-content-width'
          : 'main-content-full-width'
      "
      id="app"
    >
      <header
        v-if="currentRoute !== 'room'"
        class="main-header bg-white d-flex justify-content-between"
      >
        <div class="header-toggle">
          <div class="menu-toggle mobile-menu-icon">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <h2 class="mb-0 lable font-inter font-weight-500 text-16">
            {{ currentRoute }}
          </h2>
        </div>
        <div class="row align-items-end">
          <div v-if="isTeleCaller == 0" class="col mr-2">
            <div
              class="d-flex width-td justify-content-start align-items-center"
            >
              <input
                :checked="isOnline == 1"
                @change="changeOnline"
                type="checkbox"
                id="switch"
              /><label class="mb-0" for="switch"
                ><span class="text-14 online-status">Online</span></label
              >
              <!-- <div for="switch" class="cursor-pointer">Online</div> -->
            </div>
          </div>
          <div class="col basline">
            <div class="dropdown">
              <div class="user col align-self-end">
                <i
                  class="bi bi-person text-30 mr-2 cursy"
                  id="userDropdown"
                  alt=""
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div
                  class="dropdown-menu dropdown-menu-right cursy"
                  aria-labelledby="userDropdown"
                >
                  <div class="dropdown-header">
                    <a class="unlink" href="/profile"
                      ><i class="i-Lock-User mr-1"></i> {{ authName }}</a
                    >
                  </div>
                  <a class="dropdown-item" @click.prevent="onLogout"
                    >Sign out</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div>
        <incoming-connection-component />
      </div>
      <router-view />
    </div>
  </div>
</template>

<script setup>
import IncomingConnectionComponent from "../components/IncomingConnectionComponent.vue";
import Sidebar from "../components/Sidebar.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, onMounted, computed } from "vue";
import emitter from "tiny-emitter/instance";
import Pusher from "pusher-js";
import axios from "axios";

const store = useStore();
const router = useRouter();
const pusherChannel = ref();
const pusher = ref();
const isOnline = ref();

const authToken = computed(() => store.getters["auth/getToken"]);
const authName = computed(() => store.getters["auth/getName"]);
const channelName = computed(() => store.getters["auth/getChannel"]);
const isTeleCaller = store.getters["auth/getTeleCallerCheck"];

const currentRoute = computed(() => {
  return router.currentRoute.value.name;
});

const notificationSound = new Audio("/audio/notification-1.mp3");

onMounted(() => {
  pusherChannel.value = store.getters["auth/getPusherChannel"];
  pusher.value = store.getters["auth/getPusher"];
  subscribePusher();
  loadOnline();

  emitter.on("notSoundEnd", () => {
    console.log("notSoundEnd");
    notifySoundPause();
  });
});

function loadOnline() {
  axios.get(process.env.VUE_APP_POPIN_URL + "/api/v1/seller").then((res) => {
    isOnline.value = res.data.is_open;
  });
}

function changeOnline(event) {
  //event.target.checked
  axios
    .post(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/open", {
      is_open: event.target.checked ? 1 : 0,
    })
    .then((res) => {
      if (res.data.status == 1) {
        isOnline.value = event.target.checked ? 1 : 0;
      }
    });
}
function notifySoundPlay(type = "") {
  if (type == "loop") {
    notificationSound.loop = true;
  } else {
    notificationSound.loop = false;
  }
  notificationSound.play();
}

function notifySoundPause() {
  notificationSound.pause();
  notificationSound.currentTime = 0;
}

function subscribePusher() {
  console.log("PUSHER VAL", pusher.value);
  console.log("PUSHER CHANNEL VAL", pusherChannel.value);
  if (!pusher.value) {
    console.log("PUSHER_CONNECT");
    Pusher.logToConsole = true;
    try {
      console.log("PUSHER>", authToken.value);
      pusher.value = new Pusher("b6cb0f549999df3d07a9", {
        authEndpoint:
          process.env.VUE_APP_POPIN_URL + "/api/v1/seller/channel/authenticate",
        cluster: "ap2",
        encrypted: true,
        auth: {
          headers: {
            Authorization: `Bearer ${authToken.value}`,
            Accept: "application/json",
          },
        },
      });
      pusherChannel.value = pusher.value.subscribe(channelName.value);
      console.log(pusherChannel.value);
      store.dispatch("auth/subscribed", [pusher.value, pusherChannel.value]);
    } catch (error) {
      console.log(error);
    }
  }

  pusherChannel.value.bind("seller.message", (data) => {
    console.log("Event 0 Message", data);
    emitter.emit("newMessage", data);
    if (document.visibilityState == "hidden") {
      notifySoundPlay();
    }
  });

  pusherChannel.value.bind("seller.call_ringing_end", () => {
    console.log("Event 2 app vue");
    emitter.emit("incomingCallRingEnd");
    notifySoundPause();
  });
  pusherChannel.value.bind("seller.call_cancel", () => {
    console.log("Event 3 app vue");
    emitter.emit("incomingCallCancel");
    notifySoundPause();
  });
  pusherChannel.value.bind("seller.connection", (data) => {
    console.log("Event 4 app vue");
    data.mode = 1;
    emitter.emit("incomingConnection", data);
    notifySoundPlay("loop");
  });
  pusherChannel.value.bind("seller.participant", (data) => {
    console.log("Event 5 app vue");
    data.mode = 2;
    emitter.emit("incomingConnection", data);
    notifySoundPlay("loop");
  });
}
function onLogout() {
  console.log("LOGOUT");
  pusher.value.unsubscribe(channelName.value);
  axios.post(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/open", {
    is_open: 0,
  });
  store.dispatch("auth/logout").then(() => {
    router.push({ name: "login" });
  });
}
</script>
<style scoped>
.cursy {
  cursor: pointer;
}
.main-content-width {
  width: calc(100% - 110px) !important;
}
.main-content-full-width {
  width: 100% !important;
}
.basline {
  margin-bottom: -6px;
  margin-top: -7px;
}
.online-status {
  padding-left: 90px;
  padding-top: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.width-td {
  width: 160px !important;
}
.lable {
  font-size: 17px;
  text-transform: capitalize;
  padding: 12px 0px 12px 27px;
}
input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  width: 60px;
  height: 30px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

label:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 5px;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + label {
  background: #710ec5;
}

input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 130px;
}
</style>
