<template>
  <div class="modal fade" id="createContactModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Create Contact</h5>
        </div>
        <form @submit.prevent="createUser()">
          <div class="modal-body">
            <div class="mb-3">
              <label for="brandName" class="form-label">Name</label>
              <input
                type="text"
                class="form-control"
                id="userName"
                v-model="userForm.name"
              />
            </div>
            <div class="mb-3">
              <label for="brandName" class="form-label">Email</label>
              <input
                type="email"
                class="form-control"
                id="userName"
                v-model="userForm.email"
              />
            </div>
            <div class="mb-3">
              <label for="brandName" class="form-label">Mobile</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon3">+91</span>
                <input
                  type="tel"
                  class="form-control"
                  id="userName"
                  v-model="userForm.mobile"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { Modal } from "bootstrap";

export default {
  setup() {
    const store = useStore();

    const contactModal = ref();

    const userForm = ref({
      name: "",
      email: "",
      country: computed(() => store.getters["auth/getCountry"]),
      mobile: "",
    });

    function onCountryChanged(phoneObject) {
      userForm.country = "+" + phoneObject.countryCallingCode;
    }

    function createUser() {
      axios
        .post(
          process.env.VUE_APP_POPIN_URL + "/api/v1/seller/contacts/offline",
          {
            name: userForm.value.name,
            email: userForm.value.email,
            country: userForm.value.country,
            phone: userForm.value.mobile,
          }
        )
        .then((res) => {
          if (res.data.status == 1) {
            contactModal.value.hide();
          }
        });
    }

    onMounted(() => {
      contactModal.value = new Modal(
        document.getElementById("createContactModal")
      );
    });

    return {
      userForm,
      createUser,
      onCountryChanged,
    };
  },
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>