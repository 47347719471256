export default {
  namespaced: true,
  state: {
    prev_chat_id: "",
    chat_id: "",
    userId: "",
    name: "",
    artifact: "",
    accessToken: "",
    sellerId: "",
    userName: "",
    room: "",
    mode: "",
    websocket: "",
    status: "",
    createdAt: "",
  },
  getters: {
    userId(state) {
      return state.userId;
    },
    chatId(state) {
      return state.chat_id;
    },
    name(state) {
      return state.name;
    },
    artifact(state) {
      return state.artifact;
    },
    accessToken(state) {
      return state.accessToken;
    },
    sellerId(state) {
      return state.sellerId;
    },
    userName(state) {
      return state.userName;
    },
    room(state) {
      return state.room;
    },
    mode(state) {
      return state.mode;
    },
    websocket(state) {
      return state.websocket;
    },
    status(state) {
      return state.status;
    },
    createdAt(state) {
      return state.createdAt;
    },
  },
  mutations: {
    setStates: (state, model) => {
      if (model == "") {
        state.chat_id = "";
        state.prev_chat_id = "";
        state.userId = "";
        state.name = "";
        state.artifact = "";
        state.accessToken = "";
        state.sellerId = "";
        state.userName = "";
        state.room = "";
        state.mode = "";
        state.websocket = "";
        state.status = "";
        state.createdAt = "";
      }
    },
    setCall: (state, callDetails) => {
      state.chat_id = callDetails.callId;
      state.prev_chat_id = callDetails.callId;
      state.userId = callDetails.userId;
      state.name = callDetails.name;
      state.artifact = callDetails.artifact;
      state.accessToken = callDetails.accessToken;
      state.sellerId = callDetails.sellerId;
      state.userName = callDetails.userName;
      state.room = callDetails.room;
      state.mode = callDetails.mode;
      state.websocket = callDetails.websocket;
      state.status = callDetails.status;
      state.createdAt = callDetails.createdAt;
    },
    resetCall: (state) => {
      state.chat_id = "";
    },
  },
  actions: {
    acceptCall: ({ commit }, callDetails) => {
      commit("setCall", callDetails);
    },
    resetCall: ({ commit }) => {
      commit("resetCall");
    },
  },
};
