<template>
  <!-- Modal -->
  <div
    class="modal right fade"
    id="myModal2"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel2"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel2">Recently Viewed</h4>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <template v-if="stories.length > 0">
            <div class="row" v-for="item in stories" :key="item.id">
              <img :src="item.image" height="100" />
              <h6>
                <a :href="item.url" class="unlink underline" target="_blank">{{
                  item.title
                }}</a>
              </h6>
              <hr />
            </div>
          </template>
          <template v-else>
            No product history
          </template>
        </div>
      </div>
      <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
  </div>
  <!-- modal -->
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { onMounted, watch } from "@vue/runtime-core";
import axios from "axios";
import { defineProps } from "vue";

const props = defineProps({
  user_id: Number,
});

const stories = ref({});

function getUserStories() {
  axios
    .post(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/stories/user", {
      user_id: props.user_id,
    })
    .then((res) => {
      stories.value = res.data.stories;
      console.log("stories", stories.value);
    });
}

onMounted(() => {
  getUserStories();
});

watch(
  () => props.user_id,
  () => {
    console.log("PERMISSION");
    getUserStories();
  }
);
</script>

<style scoped>
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: calc(100vh - 115px);
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100vh;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}
</style>