import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import VueTelInput from 'vue-tel-input';
import routes from "./router";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import * as Sentry from "@sentry/vue";
import '@/assets/css/app.css'

const app = createApp(App);



const router = createRouter({
    history: createWebHistory(),
    routes: routes(store)
});

Sentry.init({
    app,
    dsn: "https://3a1071c9b43b4e1a9efa645e462aeece@o1125528.ingest.sentry.io/6654224",
    trackComponents: true,
  });
app.use(VueTelInput);
app.use(Toast);
app.use(store).use(router).mount('#app')