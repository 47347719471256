<script setup>
import { defineProps, defineEmits } from "vue";
const emit = defineEmits(["openModel"]);
const props = defineProps({
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  btnName: {
    type: String,
    required: false,
    default: "Add",
  },
  btnColor: {
    type: String,
    required: false,
    default: "#710EC5",
  },
  padding: {
    type: String,
    required: false,
    default: "12px 30px 12px 30px",
  },
  size: {
    type: String,
    required: false,
  },
  margin: {
    type: String,
    required: false,
  },
});

const theme = {
  color: props.btnColor,
};
function onClick() {
  emit("openModel", true);
}
</script>
<template>
  <button
    class="border-0 text-light font-inter-size font-inter"
    @click="onClick"
    :class="props.btnName == 'OTP' ? 'otp' : ''"
    :style="{
      'background-color': disabled == true ? '#9d98a1' : color,
      padding: padding,
      width: size,
      margin: margin,
    }"
    :disabled="disabled"
  >
    {{ props.btnName }}
  </button>
</template>

<style scoped>
button {
  background-color: v-bind("theme.color");
  border-radius: 30px;
  padding: 12px 20px 12px 20.2px;
}

.otp {
  padding: 5px 13px 6px 14px !important;
}
</style>
