import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import call from './modules/call';

export default createStore({
    modules: {
      auth,
      call,
    },
    plugins: [createPersistedState()],
});
