<template>
  <div class="main-content pt-4">
    <div class="row mb-4 justify-content-between">
      <div class="col-md-12 mb-4">
        <div class="card border-0 text-left">
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-3">
                <Datepicker
                  v-model="date"
                  inputClassName="col-md-auto"
                  placeholder="Last Week"
                  range
                  format="dd-MM-yyyy"
                  :enableTimePicker="false"
                  @update:modelValue="handleDate"
                />
              </div>
              <div class="col-2 pr-0">
                <select
                  class="form-select store-filter"
                  name="type"
                  v-model="filter.selectedBucket"
                  @change="getLeads()"
                >
                  <option
                    v-for="bucket in buckets"
                    :key="bucket.id"
                    :value="bucket.id"
                  >
                    {{ bucket.name }}
                  </option>
                </select>
              </div>
              <div class="col-2 pr-0">
                <select
                  class="form-select store-filter"
                  name="type"
                  v-model="filter.selectedStatus"
                  @change="getLeads()"
                >
                  <option value="">All</option>
                  <option value="0">Direct</option>
                  <!-- <option value="1">Accepted</option>
                    <option value="2">Declined</option> -->
                  <!-- <option value="3">Missed</option> -->
                  <option value="4">Missed</option>
                  <option value="5">Scheduled</option>
                  <option value="6">Form Fills</option>
                </select>
              </div>
              <div class="col-md-auto d-flex align-items-center">
                <span @click="exportReport()">
                  <CustomButton btnName="Export" btnColor="#710EC5" padding="12px 30px"/>
                </span>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-body">
            <div class="table-responsive">
              <div class="text-center" v-if="loader">
                  <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
              <table v-else class="display table" id="merchantsTable">
                <tbody>
                  <tr v-for="lead in leads" :key="lead.id">
                    <td class="align-middle">
                      <div class="d-flex align-items-center">
                        <span class="avatar text-light text-12 font-inter rounded-circle d-flex justify-content-center align-items-center text-uppercase">{{ lead.name.substring(0, 2) }}</span>
                        <div class="ml-12 font-inter text-14 font-weight-400">
                          {{ lead.name }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <span
                          class="badge"
                          :class="getLeadStatusClass(lead.lead_status)"
                          >{{ getLeadStatus(lead.lead_status) }}</span
                        >
                    </td>
                    <td class="align-middle font-inter text-14 font-weight-400">
                      {{ lead.contact }}
                    </td>
                    <td class="align-middle font-inter text-14 font-weight-400">
                      At {{ lead.time_human }}
                    </td>
                    <td class="align-middle d-flex justify-content-evenly">
                      <span @click="acceptLead(lead.id)">
                        <CustomButton btnName="Accept" btnColor="#1BC100" padding="12px 30px"/>
                      </span>
                      <span @click="rejectLead(lead.id)">
                        <CustomButton btnName="Reject" btnColor="#EB000D" padding="12px 30px"/>
                      </span>
                      <span @click="openMoveLeadModal(lead.id)">
                        <CustomButton btnName="Move" btnColor="#949494" padding="12px 30px"/>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!---->
          </div>
        </div>
      </div>
    </div>
    <!-- end of main-content -->

    <div class="modal fade" id="bucketUpdateModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Bucket</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="brandName">Select Bucket</label>
              <select
                class="form-select store-filter"
                name="type"
                v-model="updateBucket.bucket_id"
              >
                <option value="0">Select a bucket to move</option>
                <template v-for="bucket in buckets" :key="bucket.id">
                  <option
                    v-if="bucket.id != filter.selectedBucket"
                    :value="bucket.id"
                  >
                    {{ bucket.name }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="moveLead()"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import axios from "axios";
import { ref, onMounted, computed } from "vue";
import { useToast } from "vue-toastification";
import { Modal } from "bootstrap";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import store from "@/store";
import CustomButton from '@/components/CustomButton.vue'


const buckets = ref([]);
const leads = ref([]);
const filter = ref({
  selectedBucket: "",
  selectedStatus: "",
  start: "",
  end: "",
});
const date = ref();
const toast = useToast();
const updateBucket = ref({
  user_id: "",
  bucket_id: 0,
});
let modal = ref();
const agentId = computed(() => store.getters["auth/getAgentId"]);
const loader = ref(false)


onMounted(() => {
  getBuckets();
  modal.value = new Modal(document.getElementById("bucketUpdateModal"));
});

function getBuckets() {
  axios
    .get(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/buckets")
    .then((res) => {
      buckets.value = res.data;
      filter.value.selectedBucket = buckets.value[0].id;
      getLeads();
    });
}

function getLeads() {
  loader.value = true
  axios
    .get(
      process.env.VUE_APP_POPIN_URL +
        "/api/v1/seller/bucket/" +
        filter.value.selectedBucket +
        "?start=" +
        filter.value.start +
        "&end=" +
        filter.value.end +
        "&status=" +
        filter.value.selectedStatus
    )
    .then((res) => {
      if (res.data.status == 1) {
        leads.value = res.data.leads;
      }
    }).finally(() => {
            loader.value = false
                });
}

function acceptLead(id) {
  if (confirm("Do you really want to accept this lead?")) {
    axios
      .post(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/lead/accept", {
        id: id,
      })
      .then(() => {
        getLeads();
      });
  }
}
function rejectLead(id) {
  if (confirm("Do you really want to reject this lead?")) {
    axios
      .post(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/lead/reject", {
        id: id,
      })
      .then(() => {
        getLeads();
      });
  }
}

function handleDate(modelData) {
  if (modelData) {
    date.value = modelData;
    filter.value.start = modelData[0].toDateString();
    filter.value.end = modelData[1].toDateString();
  } else {
    filter.value.start = "";
    filter.value.end = "";
  }
  getLeads();
}

function openMoveLeadModal(id) {
  updateBucket.value.user_id = id;
  updateBucket.value.bucket_id = 0;
  modal.value.show();
}

function moveLead() {
  if (updateBucket.value.bucket_id > 0) {
    axios
      .post(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/lead/move", {
        id: updateBucket.value.user_id,
        bucket_id: updateBucket.value.bucket_id,
      })
      .then(() => {
        getLeads();
        modal.value.hide();
        toast.success("Lead moved successfully");
      });
  } else {
    toast.success("Please select a bucket to move to");
  }
}
function getLeadStatus(status) {
  if (status == 4) {
    return "Missed";
  } else if (status == 5) {
    return "Scheduled";
  } else if (status == 6) {
    return "DIRECT";
  } else {
    return "FORM FILLS";
  }
}
function getLeadStatusClass(status) {
  if (status == 4) {
    return "badge-primary";
  } else if (status == 5) {
    return "badge-info";
  } else if (status == 6) {
    return "badge-warning";
  } else {
    return "badge-secondary";
  }
}

function exportReport() {
  window.open(
    process.env.VUE_APP_POPIN_DASHBOARD_URL +
      "/api/v1/seller/" +
      agentId.value +
      "/bucket/export?bucket=" +
      filter.value.selectedBucket +
      "&start=" +
      filter.value.start +
      "&end=" +
      filter.value.end +
      "&status=" +
      filter.value.selectedStatus,
    "_blank"
  );
}
</script>
<style scoped>
::v-deep .dp__pointer::placeholder {
  color: #710EC5;
}
::v-deep .dp__pointer{
  cursor: pointer;
  border-color: #710EC5;
  border-radius: 50px;
}
::v-deep .dp__input_icon{
  color: #710EC5;
}
::v-deep .form-select{
  border-color: #710EC5 !important;
  color: #710EC5 !important;
  border-radius: 50px !important;;
}
::v-deep .badge{
  border-radius: 20px;
}
</style>
