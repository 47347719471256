<template>
  <div class="card border-0 p-2 message-box">
    <div class="profile-card">
      <!-- <div class="d-flex justify-content-end">
        <button class="btn" type="button" data-bs-toggle="modal" data-bs-target="#myModal2" title="Recently Viewed"><i class="bi bi-eye-fill text-30"></i></button>
      </div> -->
      <!-- <div class="text-center">
        <i class="bi bi-person text-30" />
        <p>{{ userProfile.name }}</p>
        <p v-if="userProfile.mobile">
          {{ userProfile.country }}{{ userProfile.mobile }}
        </p>
        <p v-if="userProfile.email">{{ userProfile.email }}</p>
      </div>
      <hr size="1" />
      <div v-for="item in userProfile.captures" :key="item.id">
        <small class="text-head">{{ item.name.toUpperCase() }}</small
        ><br />
        {{ item.value }}<br /><br />
      </div> -->
      
      <div class="d-flex justify-content-between align-items-center border-bottom pb-32 p-16">
        <span class="font-inter text-20 font-weight-600">Recently Viewed</span>
        <i class="bi bi-chevron-down"></i>
      </div>
      
      <template v-if="stories.length > 0">
      <div v-for="item in stories" :key="item">
        <div class="pt-28 d-flex align-items-center pl-20">
          <div class="grey-box">
              <img :src="item.image" class="rounded-2" />
          </div>
          <div class="pl-20 font-inter font-weight-400 text-dark text-14">{{item.title}}</div>
        </div>
      </div>
      </template>
      <template v-else>
        <p class="pt-3 pb-3 text-18 text-center">
          No recently viewed found....
        </p>
      </template>
    </div>
  </div>
</template>
<script setup>
import { ref } from "@vue/reactivity";
import { onMounted, watch } from "@vue/runtime-core";
import axios from "axios";
import { defineProps } from "vue";

const props = defineProps({
  userId: Number,
});

const userProfile = ref({});
const stories = ref({});

function getUserProfile() {
  axios
    .get(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/user/" + props.userId)
    .then((res) => {
      userProfile.value = res.data;
      console.log("------------------------"+res.data);
    });
}
function getUserStories() {
  axios
    .post(process.env.VUE_APP_POPIN_URL + "/api/v1/seller/stories/user", {
      user_id: props.userId,
    })
    .then((res) => {
      stories.value = res.data.stories;
      console.log("stories", stories.value);
    });
}

onMounted(() => {
  getUserProfile();
  getUserStories();
});

watch(
  () => props.userId,
  () => {
    getUserProfile();
  }
);
</script>

<style scoped>
@media screen and (min-width : 768px) and (max-width : 1024px){
  .profile-card,.card{
    /* height: 515px; */
    height: 135vh;
  } 
}  
.message-box{
  height: calc(100vh - 120px) !important;
}
.grey-box{
  width: 70px;
  height: 70px;
  border-radius: 8px;
}
.text-head {
  color: #663399;
}
.card {
  /* height: calc(98vh - 120px); */
  height: calc(106vh - 138px)
  /* height: 98%; */
}
.profile-card {
  overflow-y: auto;
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: #ececec;
}
::-webkit-scrollbar-thumb {
  background: #663399;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(102, 51, 150, 0.75);
}

::-webkit-scrollbar-corner {
  background: #663399;
}
</style>
